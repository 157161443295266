// Images Path
$img-path: "../img/";

// Base font
$base-font: 'Segoe UI',SegoeUI,'Microsoft YaHei',微软雅黑,"Helvetica Neue",Helvetica,Arial,sans-serif;

// Break Point
//$pc: 992px; // PC
$tab: 991px; // タブレット
$sp: 767px;  // スマホ

//カラー設定
$color_text: #333333;
$color_accent: #5d2c5a;
$color_black: #222222;
$color_white: #ffffff;
$color_gray: #666666;

// Default Letter Spacing
$default-ls: 0.04em;