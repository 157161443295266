@charset "UTF-8";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

form {
  display: inline;
}

html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

img {
  vertical-align: top;
}

ul, ol {
  list-style: none;
  letter-spacing: -0.4em;
}

ul li, ol li {
  letter-spacing: normal;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dd, dt {
  line-height: 1.42857143;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

td, th {
  padding: 0;
}

.btn-group-vertical > .btn-group:after, .btn-group-vertical > .btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
  display: table;
  content: " ";
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* clearfix */
.clearfix {
  *zoom: 1;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

html, body {
  font-weight: 400;
  font-size: 16px;
  height: 100%;
  min-height: 100%;
  line-height: 1.2;
  letter-spacing: 0.02em;
}

.pc_view {
  display: block !important;
}

.sp_view {
  display: none !important;
}

.pc_view_i {
  display: inline !important;
}

.sp_view_i {
  display: none !important;
}

.wrapper a {
  color: inherit;
  text-decoration: none;
  transition: .2s ease;
}

.wrapper a:hover {
  opacity: 0.8;
}

.wrapper .contents .container {
  position: relative;
}

.wrapper .contents .container.key_visual {
  background-image: url("../img/key.jpg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100%;
  position: relative;
  padding-top: 50%;
}

.wrapper .contents .container.key_visual .description {
  position: absolute;
  top: 16vw;
  left: 50%;
  width: 60vw;
  margin-left: -33vw;
}

.wrapper .contents .container.key_visual .description h1 {
  color: #ffffff;
  font-size: 2vw;
  line-height: 1.2;
}

.wrapper .contents .container.key_visual .description p {
  margin-top: 1vw;
  color: #ffffff;
  font-size: 1vw;
  line-height: 1.4;
}

.wrapper .contents .container.lower {
  padding-bottom: 12vw;
  background-color: #222222;
}

.wrapper .contents .container.lower > .inner {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.wrapper .contents .container.lower:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  transform: skewY(-10deg);
  transform-origin: bottom left;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #222222;
}

.wrapper .contents .container.lower h2 {
  color: #ffffff;
  margin: 40px auto;
  text-align: center;
  font-size: 24px;
}

.wrapper .contents .container.lower h2:before {
  content: "";
  display: inline-block;
  width: 130px;
  height: 30px;
  background-image: url("../img/part_w_left.png");
  background-repeat: no-repeat;
  background-position: left center;
  vertical-align: middle;
  margin-right: 6px;
}

.wrapper .contents .container.lower h2:after {
  content: "";
  display: inline-block;
  width: 130px;
  height: 30px;
  background-image: url("../img/part_w_right.png");
  background-repeat: no-repeat;
  background-position: right center;
  vertical-align: middle;
  margin-left: 6px;
}

.wrapper .contents .container.lower.bg {
  margin-top: -3vw;
  padding-bottom: 8vw;
  background-color: #ffffff;
}

.wrapper .contents .container.lower.bg > .inner {
  top: -2vw;
}

.wrapper .contents .container.lower.bg:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  transform: skewY(-10deg);
  transform-origin: bottom left;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.wrapper .contents .container.lower.bg h2 {
  color: #222222;
}

.wrapper .contents .container.lower.bg h2:before {
  background-image: url("../img/part_b_left.png");
}

.wrapper .contents .container.lower.bg h2:after {
  background-image: url("../img/part_b_right.png");
}

.wrapper .contents .container.lower.first .inner {
  max-width: 100%;
}

.wrapper .contents .container.lower.first h2 {
  margin-top: 0;
  padding-top: 60px;
}

.wrapper .contents .container.lower.first:before {
  content: none;
}

.wrapper .contents .container.lower.last {
  padding-bottom: 3vw;
}

.wrapper .contents .container.lower .link_area {
  text-align: center;
  padding: 40px 0 80px;
}

.wrapper .contents .container.lower .link_area a {
  border-radius: 4px;
  background-color: #602961;
  display: inline-block;
  width: 260px;
  padding: 20px 0;
  z-index: 10;
  color: #ffffff;
  font-size: 18px;
}

.wrapper .contents .container.lower .introduce h2 {
  font-size: 24px;
}

.wrapper .contents .container.lower .introduce h2:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 22px;
  background-image: url("../img/hana_w.png");
  background-repeat: no-repeat;
  background-position: left top;
  vertical-align: top;
}

.wrapper .contents .container.lower .introduce h2:after {
  content: none;
}

.wrapper .contents .container.lower .introduce ul {
  text-align: center;
}

.wrapper .contents .container.lower .introduce ul li {
  padding: 20px;
  display: inline-block;
}

.wrapper .contents .container.lower .list_brands {
  max-width: 1200px;
  margin: 0 auto;
}

.wrapper .contents .container.lower .list_brands ul {
  letter-spacing: -0.4em;
  padding: 0 30px;
}

.wrapper .contents .container.lower .list_brands ul li {
  letter-spacing: normal;
  display: inline-block;
  padding: 0;
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;
}

.wrapper .contents .container.lower .list_brands ul li:nth-child(3n) {
  margin-right: 0;
}

.wrapper .contents .container.lower .list_brands ul li img {
  width: 100%;
}

.wrapper .contents .container.lower .list_lp_items {
  padding: 20px 0 10px;
}

.wrapper .contents .container.lower .list_lp_items .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  z-index: 10;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  opacity: 0.8;
}

.wrapper .contents .container.lower .list_lp_items .slick-arrow.slick-prev {
  background-image: url("../img/arrow_left.svg");
  left: 12px;
}

.wrapper .contents .container.lower .list_lp_items .slick-arrow.slick-next {
  background-image: url("../img/arrow_right.svg");
  right: 12px;
}

.wrapper .contents .container.lower .list_lp_items ul {
  background-color: #fff;
}

.wrapper .contents .container.lower .list_lp_items ul li a {
  display: block;
  padding: 12px;
}

.wrapper .contents .container.lower .list_lp_items ul li a:focus {
  outline: none;
}

.wrapper .contents .container.lower .list_lp_items ul li img {
  width: 100%;
}

.wrapper .contents .container.lower .list_lp_items ul li .name {
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
}

.wrapper .contents .container.lower .list_lp_items ul li .value {
  margin-top: 6px;
  text-align: center;
}

.wrapper .contents .container.lower .list_lp_items ul li .value span {
  display: inline-block;
  margin-left: 4px;
  font-size: 12px;
}

.wrapper .contents .container.lower .detail_box {
  max-width: 800px;
  margin: 80px auto 30px;
}

.wrapper .contents .container.lower .detail_box p {
  color: #ffffff;
  line-height: 1.4;
  font-size: 16px;
}

.wrapper .contents .container.lower .detail_box .box {
  margin-top: 60px;
}

.wrapper .contents .container.lower .detail_box .box.text_right img {
  float: left;
  margin-right: 20px;
}

.wrapper .contents .container.lower .detail_box .box.text_right p {
  text-align: right;
}

.wrapper .contents .container.lower .detail_box .box.text_left img {
  float: right;
  margin-left: 20px;
}

.wrapper .contents .container.lower .list_shop {
  max-width: 800px;
  margin: 80px auto 30px;
}

.wrapper .contents .container.lower .list_shop h3 {
  color: #333333;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.wrapper .contents .container.lower .list_shop ul {
  margin-bottom: 40px;
}

.wrapper .contents .container.lower .list_shop ul li {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}

.wrapper .contents .container.lower .list_shop ul li a {
  background-color: #666666;
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  min-width: 126px;
  padding: 0 12px;
  height: 43px;
  line-height: 43px;
  text-align: center;
}

.wrapper .contents .container.lower .afterword .bnr {
  margin: 0 auto;
  width: 360px;
  display: block;
}

.wrapper .contents .container.lower .afterword h3 {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}

.wrapper .contents .container.lower .afterword p {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  margin-top: 30px;
}

.wrapper .header {
  background-color: #5d2c5a;
}

.wrapper .header > .inner {
  padding: 12px;
}

.wrapper .header > .inner h1 {
  font-size: inherit;
  font-size: 14px;
}

.wrapper .header > .inner h1 img {
  height: 14px;
}

.wrapper .footer {
  background-color: #222222;
  padding: 60px 12px;
  border-top: solid 1px #5d2c5a;
}

.wrapper .footer > .inner p {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
}

.wrapper .footer > .inner p.copyright {
  margin-top: 12px;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .pc_view {
    display: none !important;
  }
  .sp_view {
    display: inline !important;
  }
  .pc_view_i {
    display: none !important;
  }
  .sp_view_i {
    display: inline !important;
  }
  .wrapper .contents .container.key_visual {
    background-position: center top;
    background-size: 200%;
    padding-top: 100%;
    overflow: hidden;
  }
  .wrapper .contents .container.key_visual .description {
    top: 34vw;
    left: 0;
    width: 100%;
    margin-left: 0;
  }
  .wrapper .contents .container.key_visual .description h1 {
    text-align: center;
    font-size: 5.5vw;
  }
  .wrapper .contents .container.key_visual .description p {
    margin-top: 3vw;
    text-align: center;
    font-size: 3.5vw;
  }
  .wrapper .contents .container.lower h2 {
    font-size: 20px;
    padding-top: 10vw;
  }
  .wrapper .contents .container.lower h2:before {
    width: 90px;
    height: 25px;
    background-size: 100%;
  }
  .wrapper .contents .container.lower h2:after {
    width: 90px;
    height: 25px;
    background-size: 100%;
  }
  .wrapper .contents .container.lower.bg {
    margin-top: -12vw;
  }
  .wrapper .contents .container.lower .introduce {
    padding-top: 0;
  }
  .wrapper .contents .container.lower .introduce h2 {
    font-size: 20px;
    padding-top: 0;
  }
  .wrapper .contents .container.lower .introduce ul li img {
    width: 100%;
  }
  .wrapper .contents .container.lower .introduce ul li:first-child img {
    width: 49%;
  }
  .wrapper .contents .container.lower .list_brands ul {
    padding: 12px;
  }
  .wrapper .contents .container.lower .list_brands ul li {
    width: 49%;
    margin-right: 2%;
  }
  .wrapper .contents .container.lower .list_brands ul li:nth-child(3n) {
    margin-right: 2%;
  }
  .wrapper .contents .container.lower .list_brands ul li:nth-child(2n) {
    margin-right: 0;
  }
  .wrapper .contents .container.lower .list_brands + h2 {
    padding-top: 0;
  }
  .wrapper .contents .container.lower .list_lp_items {
    padding: 0 12px 10px;
  }
  .wrapper .contents .container.lower .list_lp_items .slick-arrow.slick-prev {
    left: 0;
  }
  .wrapper .contents .container.lower .list_lp_items .slick-arrow.slick-next {
    right: 0;
  }
  .wrapper .contents .container.lower .list_lp_items ul li .name {
    font-size: 12px;
  }
  .wrapper .contents .container.lower .list_lp_items ul li .value {
    font-size: 12px;
  }
  .wrapper .contents .container.lower .detail_box {
    margin-top: 50px;
    padding: 0 12px;
  }
  .wrapper .contents .container.lower .detail_box p {
    margin: 30px auto;
  }
  .wrapper .contents .container.lower .detail_box .box {
    margin-top: 0;
  }
  .wrapper .contents .container.lower .detail_box .box.text_right img {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  .wrapper .contents .container.lower .detail_box .box.text_right p {
    text-align: left;
  }
  .wrapper .contents .container.lower .detail_box .box.text_left img {
    float: none;
    width: 100%;
    margin-left: 0;
  }
  .wrapper .contents .container.lower .list_shop {
    margin: 50px auto 30px;
    padding: 0 12px;
  }
  .wrapper .contents .container.lower .list_shop h3 {
    font-size: 18px;
  }
  .wrapper .contents .container.lower .list_shop ul {
    margin-bottom: 20px;
  }
  .wrapper .contents .container.lower .list_shop ul li a {
    min-width: auto;
  }
  .wrapper .contents .container.lower .afterword {
    padding: 12vw 12px 10vw;
  }
  .wrapper .contents .container.lower .afterword .bnr {
    max-width: 360px;
    width: 80%;
  }
  .wrapper .contents .container.lower .afterword .bnr img {
    width: 100%;
  }
  .wrapper .contents .container.lower .afterword p {
    text-align: left;
  }
  .wrapper .header > .inner h1 {
    text-align: center;
  }
}
