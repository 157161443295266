@import 'settings';
@import 'mixin';
@import 'reset';

/* clearfix */
.clearfix {
	*zoom: 1;
	&:after {
		content: ".";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
	}
}

::-webkit-input-placeholder {
}

html,body {
	font-weight: 400;
	font-size: 16px;
	height: 100%;
	min-height: 100%;
	line-height: 1.2;
	letter-spacing: 0.02em;
}
body {
}

.pc_view {
	display: block !important;
}
.sp_view {
	display: none !important;
}
.pc_view_i {
	display: inline !important;
}
.sp_view_i {
	display: none !important;
}

.wrapper {
	a {
		color: inherit;
		text-decoration:none;
		transition: .2s ease;
		&:hover {
			opacity: 0.8;
		}
	}
	>.inner {
	}
	.contents {
		>.inner {
		}
		.container {
			position: relative;
			&.key_visual {
				background-image: url('#{$img_path}key.jpg');
				background-repeat: no-repeat;
				background-position: left top;
				background-size: 100%;
				position: relative;
				padding-top: 50%;
				>.inner {
				}
				.description {
					position: absolute;
					top: 16vw;
					left: 50%;
					width: 60vw;
					margin-left: -33vw;
					// -moz-transform: rotate(-20deg);
					// -webkit-transform: rotate(-20deg);
					// -ms-transform: rotate(-20deg);
					h1 {
						color: $color_white;
						font-size: 2vw;
						line-height: 1.2;
					}
					p {
						margin-top: 1vw;
						color: $color_white;
						font-size: 1vw;
						line-height: 1.4;
					}
				}
			}
			&.lower {
      	padding-bottom: 12vw;
				background-color: $color_black;
				>.inner {
					max-width: 1280px;
					margin: 0 auto;
	        position: relative;
	        z-index: 2;
				}
        &:before {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          transform: skewY(-10deg);
          transform-origin: bottom left;
          z-index: 1;
          width: 100%;
          height: 100%;
          background:  $color_black;
        }
				h2 {
					color: $color_white;
					margin: 40px auto;
					text-align: center;
					font-size: 24px;
					&:before {
						content:"";
						display: inline-block;
						width: 130px;
						height: 30px;
						background-image: url('#{$img_path}part_w_left.png');
						background-repeat: no-repeat;
						background-position: left center;
						vertical-align: middle;
						margin-right: 6px;
						// background-size: 100%;
					}
					&:after {
						content:"";
						display: inline-block;
						width: 130px;
						height: 30px;
						background-image: url('#{$img_path}part_w_right.png');
						background-repeat: no-repeat;
						background-position: right center;
						vertical-align: middle;
						margin-left: 6px;
						// background-size: 100%;
					}
				}
	      &.bg {
	      	margin-top: -3vw;
	        padding-bottom: 8vw;
	        background-color: $color_white;
	        >.inner {
	          top: -2vw;
	        }
	        &:before {
	          display: block;
	          content: '';
	          position: absolute;
	          left: 0;
	          transform: skewY(-10deg);
	          transform-origin: bottom left;
	          z-index: 1;
	          width: 100%;
	          height: 100%;
	          background:  $color_white;
	        }
					h2 {
						color: $color_black;
						&:before {
							background-image: url('#{$img_path}part_b_left.png');
						}
						&:after {
							background-image: url('#{$img_path}part_b_right.png');
						}
					}
	      }
        &.first {
        	.inner {
        		max-width: 100%;
        	}
        	h2 {
						margin-top: 0;
						padding-top: 60px;
        	}
          &:before {
        		content: none;
        	}
        }
        &.last {
					padding-bottom: 3vw;
        }
				.link_area {
					text-align: center;
					padding: 40px 0 80px;
					a {
						border-radius: 4px;
						background-color: rgb(96, 41, 97);
						display: inline-block;
						width: 260px;
						padding: 20px 0;
						z-index: 10;
						color: $color_white;
						font-size: 18px;
					}
				}
				.introduce {
					h2 {
						font-size: 24px;
						&:before {
							content: "";
							display: inline-block;
							width: 24px;
							height: 22px;
							background-image: url('#{$img_path}hana_w.png');
							background-repeat: no-repeat;
							background-position: left top;
							vertical-align: top;
							// background-size: 100%;
						}
						&:after {
							content: none;
						}
					}
					ul {
						text-align: center;
						li {
							padding: 20px;
							display: inline-block;
						}
					}
				}
				.list_brands {
					max-width: 1200px;
					margin: 0 auto;
					ul {
						letter-spacing: -0.4em;
						padding: 0 30px;
						li {
							letter-spacing: normal;
							display: inline-block;
							padding: 0;
							width: 32%;
							margin-right: 2%;
							margin-bottom: 2%;
							&:nth-child(3n) {
								margin-right: 0;
							}
							img {
								width: 100%;
							}
						}
					}
				}
				.list_lp_items {
			    padding: 20px 0 10px;
			    // border: solid 2px #eee;
					.slick-arrow {
						position: absolute;
						top: 50%;
						margin-top: -20px;
						width: 40px;
						height: 40px;
						z-index: 10;
						white-space: nowrap;
						text-indent: 200%;
						overflow: hidden;
						background-color: transparent;
						cursor: pointer;
						outline: none;
						border: none;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 80%;
						opacity: 0.8;
						&.slick-prev {
							background-image: url('#{$img_path}arrow_left.svg');
							left: 12px;
						}
						&.slick-next {
							background-image: url('#{$img_path}arrow_right.svg');
							right: 12px;
						}
					}
					ul {
						background-color: #fff;
						li {
							a {
								display: block;
								padding: 12px;
								&:focus {
									outline: none;
								}
							}
							img {
								width: 100%;
							}
							.name {
								margin-top: 6px;
								overflow: hidden;
								white-space: nowrap;
								text-align: center;
								font-weight: bold;
							}
							.value {
								margin-top: 6px;
								text-align: center;
								span {
									display: inline-block;
									margin-left: 4px;
									font-size: 12px;
								}
							}
						}
					}
				}
				.detail_box {
					max-width: 800px;
					margin: 80px auto 30px;
					p {
						color: $color_white;
						line-height: 1.4;
						font-size: 16px;
					}
					.box {
						margin-top: 60px;
						&.text_right {
							img {
								float: left;
								margin-right: 20px;
							}
							p {
								text-align: right;
							}
						}
						&.text_left {
							img {
								float: right;
								margin-left: 20px;
							}
						}
					}
				}
				.list_shop {
					max-width: 800px;
					margin: 80px auto 30px;
					h3 {
						color: $color_text;
						font-weight: bold;
						font-size: 20px;
						margin-bottom: 20px;
					}
					ul {
						margin-bottom: 40px;
						li {
							display: inline-block;
							margin-right: 12px;
							margin-bottom: 12px;
							a {
								background-color: $color_gray;
								color: $color_white;
								font-size: 16px;
								display: inline-block;
								border-style: solid;
								border-width: 1px;
								// border-color: rgb(67, 67, 67);
								border-radius: 8px;
								min-width: 126px;
								padding: 0 12px;
								height: 43px;
								line-height: 43px;
								text-align: center;
								// font-weight: bold;
							}
						}
					}
				}
				.afterword {
					.bnr {
						margin: 0 auto;
						width: 360px;
						display: block;
					}
					h3 {
						color: $color_white;
						text-align: center;
						font-size: 20px;
						margin-top: 30px;
					}
					p {
						color: $color_white;
						text-align: center;
						font-size: 16px;
						line-height: 1.4;
						margin-top: 30px;
					}
				}
			}
		}
	}
	.header {
		background-color: $color_accent;
		>.inner {
			padding: 12px;
			h1 {
				font-size: inherit;
				font-size: 14px;
				img {
					height: 14px;
				}
			}
		}
	}
	.footer {
		background-color: $color_black;
		padding: 60px 12px;
		border-top: solid 1px $color_accent;
		>.inner {
			p {
				text-align: center;
				color: $color_white;
				font-size: 12px;
				&.copyright {
					margin-top: 12px;
					font-size: 12px;
				}
			}
		}
	}
}

@media screen and (max-width: $sp) {
	.pc_view {
		display: none !important;
	}
	.sp_view {
		display: inline !important;
	}
	.pc_view_i {
		display: none !important;
	}
	.sp_view_i {
		display: inline !important;
	}

	.wrapper {
		a {
			&:hover {
			}
		}
		>.inner {
		}
		.contents {
			>.inner {
			}
			.container {
				&.key_visual {
					background-position: center top;
					background-size: 200%;
					padding-top: 100%;
					overflow: hidden;
					>.inner {
					}
					.description {
						top: 34vw;
						left: 0;
						width: 100%;
						margin-left: 0;
						h1 {
							text-align: center;
							font-size: 5.5vw;
						}
						p {
							margin-top: 3vw;
							text-align: center;
							font-size: 3.5vw;
						}
					}
				}
				&.lower {
					>.inner {
					}
	        &:before {
	        }
					h2 {
						font-size: 20px;
						padding-top: 10vw;
						&:before {
							width: 90px;
							height: 25px;
							background-size: 100%;
						}
						&:after {
							width: 90px;
							height: 25px;
							background-size: 100%;
						}
					}
		      &.bg {
		      	margin-top: -12vw;
		        >.inner {
		        }
		        &:before {
		        }
						h2 {
						}
		      }
	        &.first {
	          &:before {
	        	}
	        }
	        &.last {
	        }
					.link_area {
						a {
						}
					}
					.introduce {
						padding-top: 0;
						h2 {
							font-size: 20px;
							padding-top: 0;
						}
						ul {
							li {
								img {
									width: 100%;
								}
								&:first-child {
									img {
										width: 49%;
									}
								}
							}
						}
					}
					.list_brands {
						ul {
							padding: 12px;
							li {
								width: 49%;
								margin-right: 2%;
								&:nth-child(3n) {
									margin-right: 2%;
								}
								&:nth-child(2n) {
									margin-right: 0;
								}
								img {
								}
							}
						}
						& + h2 {
							padding-top: 0;
						}
					}
					.list_lp_items {
						padding: 0 12px 10px;
						.slick-arrow {
							&.slick-prev {
								left: 0;
							}
							&.slick-next {
								right: 0;
							}
						}
						ul {
							li {
								a {
									&:focus {
									}
								}
								img {
								}
								.name {
									font-size: 12px;
								}
								.value {
									font-size: 12px;
								}
							}
						}
					}
					.detail_box {
						margin-top: 50px;
						padding: 0 12px;
						p {
							margin: 30px auto;
						}
						.box {
							margin-top: 0;
							&.text_right {
								img {
									float: none;
									width: 100%;
									margin-right: 0;
								}
								p {
									text-align: left;
								}
							}
							&.text_left {
								img {
									float: none;
									width: 100%;
									margin-left: 0;
								}
							}
						}
					}
					.list_shop {
						margin: 50px auto 30px;
						padding: 0 12px;
						h3 {
							font-size: 18px;
						}
						ul {
							margin-bottom: 20px;
							li {
								a {
									min-width: auto;
								}
							}
						}
					}
					.afterword {
						padding: 12vw 12px 10vw;
						.bnr {
							max-width: 360px;
							width: 80%;
							img {
								width: 100%;
							}
						}
						h3 {
						}
						p {
							text-align: left;
						}
					}
				}
			}
		}
		.header {
			>.inner {
				h1 {
					text-align: center;
					img {
					}
				}
			}
		}
		.footer {
			>.inner {
				p {
					&.copyright {
					}
				}
			}
		}
	}
}
